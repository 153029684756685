import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_SlowpokeSurveysDetailsTable = _resolveComponent("SlowpokeSurveysDetailsTable")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    header: _withCtx(() => [
      _createTextVNode("Surveys")
    ]),
    content: _withCtx(() => [
      _createVNode(_component_DataLoader, {
        "is-loading": _ctx.isLoading(_ctx.data.surveys)
      }, null, 8, ["is-loading"]),
      _createVNode(_component_DataLoadingError, {
        subject: _ctx.data.surveys
      }, null, 8, ["subject"]),
      (_ctx.isLoaded(_ctx.data.surveys))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_va_select, {
              class: "mb-4",
              bordered: "",
              label: "Survey variant",
              modelValue: _ctx.data.variant,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.variant) = $event)),
                _cache[1] || (_cache[1] = (variant) => _ctx.variantSelected(variant))
              ],
              options: _ctx.variantOptions
            }, null, 8, ["modelValue", "options"]),
            _createVNode(_component_Pagination, {
              class: "mb-2",
              entity: _ctx.data.surveys.data
            }, null, 8, ["entity"]),
            _createVNode(_component_SlowpokeSurveysDetailsTable, {
              surveys: _ctx.data.surveys.data.items
            }, null, 8, ["surveys"]),
            _createVNode(_component_Pagination, {
              class: "mt-3",
              entity: _ctx.data.surveys.data
            }, null, 8, ["entity"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}