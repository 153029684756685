import { simpleAuthorizedQuery } from '@/utils/fetch'
import { slowpokeApiUrl } from '@/modules/slowpoke/api/_shared'
import { Survey, SurveyVariants } from '@/modules/slowpoke/model/surveys.model'
import { PagedResponse } from '@/api/pagination.api'

export interface GetSurveysOptions {
  variant?: string
  limit?: number
  cursor?: string
}

export async function getSurveyVariants(): Promise<SurveyVariants> {
  return simpleAuthorizedQuery<SurveyVariants>(slowpokeApiUrl(`admin/surveys/variants`))
}

export async function getSurveys(options: GetSurveysOptions = {}): Promise<PagedResponse<Survey>> {
  const params = new URLSearchParams()

  if (options.variant) {
    params.set('variant', String(options.variant))
  }

  if (options.limit) {
    params.set('limit', String(options.limit))
  }

  if (options.cursor) {
    params.set('cursor', String(options.cursor))
  }

  const res = await simpleAuthorizedQuery<PagedResponse<Survey>>(slowpokeApiUrl(`admin/surveys?${params.toString()}`))

  return {
    items: (res.items || []).map((v) => new Survey(v)),
    prev: res.prev,
    next: res.next,
  }
}
