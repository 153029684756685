import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_button = _resolveComponent("va-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_va_button, {
      disabled: !_ctx.entity.prev,
      onClick: _ctx.prevPage,
      class: "mr-2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_va_icon, {
          name: "chevron_left",
          size: "small"
        })
      ]),
      _: 1
    }, 8, ["disabled", "onClick"]),
    _createVNode(_component_va_button, {
      disabled: !_ctx.entity.next,
      onClick: _ctx.nextPage
    }, {
      default: _withCtx(() => [
        _createVNode(_component_va_icon, {
          name: "chevron_right",
          size: "small"
        })
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}