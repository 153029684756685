export type SurveyVariants = Record<string, string>

export class Survey {
  id?: number
  uuid?: string
  variant: string
  clientId: number
  email: string
  payload: Record<string, string | number>
  createdAt: Date
  updatedAt: Date

  constructor(doc: Partial<Survey>) {
    this.id = doc.id || undefined
    this.uuid = doc.uuid || undefined
    this.variant = doc.variant || ''
    this.clientId = doc.clientId || 0
    this.email = doc.email || ''
    this.payload = Survey.parsePayload(doc.payload)
    this.updatedAt = doc.updatedAt ? new Date(doc.updatedAt) : new Date()
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
  }

  static parsePayload(raw?: string | Survey['payload']): Survey['payload'] {
    try {
      if (!raw) {
        return {}
      }

      if (typeof raw === 'object') {
        return raw
      }

      return JSON.parse(raw)
    } catch (e) {
      return {}
    }
  }
}
