
import { defineComponent, PropType } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { PagedResponse } from '@/api/pagination.api'

export default defineComponent({
  name: 'Pagination',
  props: {
    entity: {
      type: Object as PropType<PagedResponse<unknown>>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    const prevPage = () => {
      router.push({ query: { ...route.query, page: props.entity.prev } })
    }

    const nextPage = () => {
      router.push({ query: { ...route.query, page: props.entity.next } })
    }

    return { prevPage, nextPage }
  },
})
