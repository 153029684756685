
import { defineComponent, PropType } from 'vue'
import { Survey } from '@/modules/slowpoke/model/surveys.model'

export default defineComponent({
  name: 'SlowpokeSurveysDetailsTable',
  components: {},
  props: {
    surveys: {
      type: Array as PropType<Survey[]>,
      required: true,
    },
  },
})
