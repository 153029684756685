
import { computed, defineComponent, reactive, watch } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import Panel from '@/components/UI/Panel.vue'
import { Survey, SurveyVariants } from '@/modules/slowpoke/model/surveys.model'
import { GetSurveysOptions, getSurveys, getSurveyVariants } from '@/modules/slowpoke/api/surveys.api'
import SlowpokeSurveysDetailsTable from '@/modules/slowpoke/components/surveys/SurveysDetailsTable.vue'
import { PagedResponse } from '@/api/pagination.api'
import Pagination from '@/components/UI/Pagination.vue'
import { useRoute, useRouter } from 'vue-router'

type VariantOpt = {
  value: string
  text: string
}

export default defineComponent({
  name: 'SlowpokeSurveys',
  components: { Pagination, SlowpokeSurveysDetailsTable, Panel, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      surveys: LOADING_STATE as LoadingState<PagedResponse<Survey>>,
      variants: LOADING_STATE as LoadingState<SurveyVariants>,
      variant: null as null | VariantOpt,
    })

    const route = useRoute()
    const router = useRouter()

    const loadVariants = async () => {
      try {
        data.variants = LOADING_STATE
        const variants = await getSurveyVariants()
        data.variants = loaded(variants)

        if (route.query.variant && typeof route.query.variant === 'string') {
          const key = Object.keys(variants).find((v) => v === String(route.query.variant))

          if (key) {
            data.variant = { text: `[${key}]: ${variants[key]}`, value: key }
          }
        }
      } catch (e) {
        data.variants = errored(e)
      }
    }

    const loadSurveys = async () => {
      try {
        const options: GetSurveysOptions = { limit: 100 }

        if (route.query.page && typeof route.query.page === 'string') {
          options.cursor = route.query.page
        }

        if (data.variant) {
          options.variant = data.variant.value
        }

        data.surveys = LOADING_STATE
        data.surveys = loaded(await getSurveys(options))
      } catch (e) {
        data.surveys = errored(e)
      }
    }

    const variantOptions = computed(() => {
      if (!isLoaded(data.variants)) {
        return []
      }

      const variants = data.variants.data

      return Object.keys(variants).map((key) => ({
        text: `[${key}]: ${variants[key]}`,
        value: key,
      }))
    })

    const variantSelected = (variant: VariantOpt) => {
      router.push({ query: { ...route.query, variant: variant.value, page: undefined } })
    }

    watch(
      () => route.query,
      () => {
        loadSurveys()
      }
    )

    loadVariants().then(() => {
      loadSurveys()
    })

    return {
      data,
      isLoaded,
      isLoading,
      isErrored,
      variantOptions,
      variantSelected,
    }
  },
})
